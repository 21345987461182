<template>
  <div>
    <div class='plans-select'>
      <md-field class='plans-select-dropdown'>
        <label for='plan'>{{ $t('label.plans') }}</label>
        <md-select
          v-model='selected_plan'
          name='plan'
          id='plan'
          @md-selected='onPlanChange'
        >
          <md-option v-for='plan in planList' :key='plan.id' :value='plan.id'>
            {{ plan.name }}
          </md-option>
        </md-select>
      </md-field>
    </div>
    <div class='md-layout'>
      <!--div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="blue">
          <template slot="header">
            <div class="card-icon">
              <md-icon>calendar_today</md-icon>
            </div>
            <p class="category">{{ `${$t('statscard.maintenance')}` }}</p>
            <h3 class="title">
              <animated-number :value="245"></animated-number>
            </h3>
          </template>

          <template slot="footer">
            <div class="stats">
            <md-icon>update</md-icon>
            10 мин
          </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="rose">
          <template slot="header">
            <div class="card-icon">
              <md-icon>repeat</md-icon>
            </div>
            <p class="category">
              {{ `${$t('statscard.routine_maintenance ')}` }}
            </p>
            <h3 class="title">
              <animated-number :value="75"></animated-number>
              .
              <animated-number :value="521"></animated-number>
            </h3>
          </template>

          <template slot="footer">
            <div class="stats">
            <md-icon>local_offer</md-icon>
            Tracked from Planning Analytics Tool
          </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon">
              <md-icon>store</md-icon>
            </div>
            <p class="category">
              {{ `${$t('statscard.сapital_investments')}` }}
            </p>
            <h3 class="title">
              <animated-number :value="334"></animated-number>
              ,
              <animated-number :value="245"></animated-number>
            </h3>
          </template>

          <template slot="footer">
            <div class="stats">
            <md-icon>date_range</md-icon>
            Last
            <animated-number :value="24"></animated-number>
            Hours
          </div>
          </template>
        </stats-card>
      </div>
      <div--
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="warning">
          <template slot="header">
            <div class="card-icon">
              <md-icon>view_quilt</md-icon>
            </div>
            <p class="category">{{ `${$t('statscard.patching_work')}` }}</p>
            <h3 class="title">
              <animated-number :value="184"></animated-number>
            </h3>
          </template>

          <template slot="footer">
            <div class="stats">
            <md-icon class="text-danger">warning</md-icon>
            <a href="#pablo">Get More Space...</a>
          </div>
          </template>
        </stats-card>
      </div-->
      <div
        class='md-layout-item md-size-50 md-small-size-100 md-medium-size-50'
      >
        <chart-card
          header-animation='false'
          :chart-data='multipleBarsChart.data'
          :chart-options='multipleBarsChart.options'
          chart-type='Bar'
          header-icon
          chart-inside-content
          background-color='green'
          :dataIsLoading='chartsDataIsLoading'
        >
          <template slot='chartInsideHeader'>
            <div class='card-icon'>
              <md-icon>insert_chart</md-icon>
            </div>
            <h4 class='title'>
              {{ $t('dashboard.cost_of_work_by_organizations') }}

            </h4>
          </template>
          <template slot='footer'>
            <div class='md-layout'>
              <!--div class="md-layout-item md-size-100">
                <h6 class="category">Legend</h6>
              </div-->
              <div
                v-for='(name, ind) in multipleBarsChart.data.legend'
                :key='name'
                class='md-layout-item'
              >
                <i class='fa fa-circle' :class='[legend_color[ind]]'></i>
                {{ $t(name) }}
              </div>
            </div>
          </template>
        </chart-card>
      </div>
      <div class='md-layout-item md-size-50 md-small-size-100'>
        <chart-card
          header-animation='false'
          :chart-data='pieChart.data'
          :chart-options='pieChart.options'
          chart-type='Pie'
          header-icon
          chart-inside-content
          background-color='green'
          :dataIsLoading='chartsDataIsLoading'
        >
          <template slot='chartInsideHeader'>
            <div class='card-icon'>
              <md-icon>pie_chart</md-icon>
            </div>
            <h4 class='title'>

              {{ $t('dashboard.work_types') }}
            </h4>
          </template>
          <template slot='footer'>
            <div class='md-layout'>
              <!--div class="md-layout-item md-size-100">
                <h6 class="category">Legend</h6>
              </div-->
              <div
                class='md-layout-item'
                v-for='(name, ind) in pieChart.data.legend'
                :key='name'
              >
                <i class='fa fa-circle' :class='[legend_color[ind]]'></i>
                {{ $t(name) }}
              </div>
            </div>
          </template>
        </chart-card>
      </div>
    </div>
  </div>
</template>

<script>
import {ChartCard} from '@/components'
import {mapState} from 'vuex'

export default {
  components: {
    //StatsCard,
    ChartCard
    //AnimatedNumber
  },
  data() {
    return {
      selected_plan: undefined,
      legend_color: ['text-info', 'text-danger', 'text-warning'],
      chartsDataIsLoading: true,
      pieChart: {
        data: {
          labels: [],
          series: [],
          legend: []
        },
        options: {
          height: '300px'
        }
      },
      multipleBarsChart: {
        data: {
          labels: [],
          series: [],
          legend: []
        },
        options: {
          seriesBarDistance: 40,
          axisX: {
            showGrid: false
          },
          height: '300px',
          scales: {
            xAxes: [
              {
                ticks: {
                  autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90
                }
              }
            ]
          }
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0]
                }
              }
            }
          ]
        ]
      },
      cards: []
    }
  },
  computed: {
    ...mapState({
      currentPlan: (state) => state.Plans.currentPlan,
      planList: (state) => state.Plans.list
    })
  },
  async created() {
    this.chartsDataIsLoading = true
    await this.$store.dispatch('LOAD_PLAN_LIST', {})
    this.selected_plan = this.currentPlan
      ? this.currentPlan
      : this.planList[0].id
    //this.reloadChartData()
  },
  methods: {
    onPlanChange() {
      this.reloadChartData()
    },
    async reloadChartData() {
      this.chartsDataIsLoading = true
      try {

        this.multipleBarsChart.data.labels = []
        this.multipleBarsChart.data.series = []
        this.multipleBarsChart.data.legend = []
        this.pieChart.data.labels = []
        this.pieChart.data.series = []
        this.pieChart.data.legend = []
        this.cards = []

        const res = await this.$store.dispatch(
          'LOAD_DASHBOARD_CHARTS',
          this.selected_plan
        )

        const brChartData = res.barchart.data
        const pChartData = res.piechart.data
        this.cards = res.cards

        this.multipleBarsChart.data.labels = brChartData.dimensions
        brChartData.measures.forEach((mr, index) => {
          this.$set(
            this.multipleBarsChart.data.series,
            index,
            mr.map((val) => val / 1000000)
          )
        })
        this.multipleBarsChart.data.legend = brChartData.legend

        this.pieChart.data.legend = pChartData.legend
        this.pieChart.data.labels = Object.keys(pChartData.measures).map(
          (item) => `${pChartData.measures[item]}`
        )
        this.pieChart.data.series = Object.keys(pChartData.measures).map(
          (item) => pChartData.measures[item]
        )
      } catch (err) {
        this.multipleBarsChart.data.labels = []
        this.multipleBarsChart.data.series = []
        this.multipleBarsChart.data.legend = []
        this.pieChart.data.labels = []
        this.pieChart.data.series = []
        this.pieChart.data.legend = []
        this.cards = []
      } finally {
        this.chartsDataIsLoading = false
      }
    }
  }
}
</script>
<style lang='scss'>
.plans-select {
  display: flex;
  position: inherit;
  top: 20px;
  margin-left: auto;
  align-items: center;
  justify-content: flex-end;

  .plans-select-dropdown {
    max-width: 300px;
  }
}

.ct-bar {
  stroke-width: 40px
}
</style>
